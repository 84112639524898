import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';
import * as _ from 'lodash';

export const myContract = async () => {
	try {
		const { data } = await apollo.query({
			query: gql`
				query {
					MyContract {
						${columns}
					}
				}
			`,
			variables: {},
			fetchPolicy: 'no-cache'
		});

		return data.MyContract;
	} catch (e) {
		console.log({ e });
	}
};

export const myContracts = async () => {
	//console.log("in crud myContracts " );
	try {
		const { data } = await apollo.query({
			query: gql`
				query {
					MyContracts {
						${columns}
					}
				}
			`,
			variables: {},
			fetchPolicy: 'no-cache'
		});

		return data.MyContracts;
	} catch (e) {
		console.log({ e });
	}
};

export const getContractTemplate = async (departmentNumber = 0, functionNumber = 0, projectId = null) => {
	const {
		data: { GetContractTemplate }
	} = await apollo.query({
		query: gql`
			query ($departmentNumber: Int!, $functionNumber: Int!, $projectId: ID) {
				GetContractTemplate(DepartmentNumber: $departmentNumber, FunctionNumber: $functionNumber, ProjectId: $projectId) {
					${contractTemplateColumns}
				}
			}
		`,
		variables: {
			departmentNumber: parseInt(departmentNumber, 10),
			functionNumber: parseInt(functionNumber, 10),
			projectId: !_.isNil(projectId) ? +projectId : null
		},
		fetchPolicy: 'no-cache'
	});

	return GetContractTemplate;
};

export const addUpdContractTemplate = async (newContractTemplate = {}) => {
	const mutation = gql`
		mutation ADD_UPD_CONTRACT_TEMPLATE($newContractTemplate: ContractTemplateInput!) {
			addUpdContractTemplate(NewContractTemplate: $newContractTemplate)
		}
	`;

	const {
		data: { addUpdContractTemplate }
	} = await apollo.mutate({
		mutation,
		variables: {
			newContractTemplate
		},
		fetchPolicy: 'no-cache'
	});

	return addUpdContractTemplate;
};

export const contracts = async (userId = 0, jobRequestId = null) => {
	const {
		data: { Contracts }
	} = await apollo.query({
		query: gql`
			query ($userId: ID!, $jobRequestId: ID) {
				Contracts(UserId: $userId, JobRequestId: $jobRequestId) {
					${columns}
				}
			}
		`,
		variables: {
			userId: parseInt(userId, 10),
			jobRequestId: +jobRequestId
		},
		fetchPolicy: 'no-cache'
	});

	return Contracts;
};

export const dataCcn2642 = async (timeManagementType, refSalary, workSixDay = null) => {
	const mutation = gql`
		mutation ($workSixDay: ID, $timeManagementType: ID!, $refSalary: Float!) {
			ChangeTimeManagement(WorkSixDay: $workSixDay, TimeManagementType: $timeManagementType, RefSalary: $refSalary) {
				hourlyRate
				dailyRate
				totMinPerDay
				totMinPerDayStr
				weeklyFiveDayMinmumSalary
				weeklyFiveDaysRefSalary
				totMinPerWeek
				totMinPerWeekStr
				monthlyRate
				cdiMonthlyReferenceSalary
			}
		}
	`;

	let variables = {
		timeManagementType: +timeManagementType,
		refSalary: +refSalary
	};

	if (workSixDay && +workSixDay > 0) {
		variables = {
			...variables,
			workSixDay: +workSixDay
		};
	}

	const {
		data: { ChangeTimeManagement }
	} = await apollo.mutate({
		mutation,
		variables,
		fetchPolicy: 'no-cache'
	});

	return ChangeTimeManagement;
};

export const addContractService = async (userid) => {
	const mutation_newContract = gql`
		mutation ($userid: ID!) {
			NewContract(UserId: $userid) {
				id
			}
		}
	`;

	const {
		data: { NewContract }
	} = await apollo.mutate({
		mutation: mutation_newContract,
		variables: {
			userid
		}
	});

	return NewContract;
};

export const deleteContractService = async (contractId) => {
	const mutation_delContract = gql`
		mutation ($contractId: ID!) {
			DelContract(ContractId: $contractId)
		}
	`;

	const {
		data: { DelContract }
	} = await apollo.mutate({
		mutation: mutation_delContract,
		variables: {
			contractId: +contractId
		}
	});

	return DelContract;
};

export const sendDpaeService = async (contractId) => {
	const mutation_sendDpae = gql`
		mutation ($contractId: ID!) {
			CreateDPAE(ContractId: $contractId)
		}
	`;

	const {
		data: { CreateDPAE }
	} = await apollo.mutate({
		mutation: mutation_sendDpae,
		variables: {
			contractId: +contractId
		}
	});

	return CreateDPAE;
};

export const updateContract = async (id, contractInput, customFieldValueInputs = null, jobRequestId = null) => {
	const contractId = +id;

	const mutation = gql`
		mutation ($contractId: ID!, $contractInput: ContractInput!, $jobRequestId: ID, $customFieldValueInputs: [CustomFieldValueInput]) {
			UpdContract(ContractId: $contractId, UpdatedContract: $contractInput, JobRequestId: $jobRequestId, CustomFieldValueInput: $customFieldValueInputs) {
				id
			}
		}
	`;

	let variables = {
		contractId,
		contractInput
	};

	if (!_.isNil(customFieldValueInputs)) {
		variables = {
			...variables,
			customFieldValueInputs
		};
	}

	if (!_.isNil(jobRequestId)) {
		jobRequestId = +jobRequestId;
		variables = {
			...variables,
			jobRequestId
		};
	}

	const {
		data: { UpdContract }
	} = await apollo.mutate({
		mutation,
		variables
	});

	return UpdContract;
};

const columns = `
	id
	startDate
	startTime
	endDate
	dailyRate
	overtimeRate
	nightTimeRate
	kmRate
	hourlyRate
	nightTimeStart
	nightTimeEnd
	totMinPerDay
	totMinPerWeek
	departmentName
	salaryType
	functionName
	hotelPerDiem
	dailyRightTransfer
	defaultDayType
	lunchPerDiem
	lunchMinimum
	travelDailyRate
	sixthDay
	seventhDay
	daysForeseen
	hourBeforeTimeRate
	minTimeBetweenDays
	totMinPerDayStr
	totMinPerWeekStr
	department
	function
	timeManagementType
	weeklyOvertime
	workSixDays
	weeklyFiveDaysMinimumSalary
	weeklySixDaysMinimumSalary
	weeklyFiveDaysEffNumHours
	weeklyFiveDaysEquNumHours
	weeklySixDaysEffNumHours
	weeklySixDaysEquNumHours
	weeklyBonusAmount
	weeklyFiveDaysRefSalary
	weeklySixDaysRefSalary
	weeklyOvtOneLimit
	weeklyOvtOneRate
	weeklyOvtTwoLimit
	weeklyOvtTwoRate
	weeklyOvtThreeLimit
	weeklyOvtThreeRate
	weeklyBaseEffNumHours
	hourlyRateAnnexThree
	weeklyBonusAmountFiveDays
	weeklyBonusAmountSixDays
	weeklyBaseRefSalary
	weeklyBaseMinimumSalary
	weeklyFiveDaysMinimumSalaryAnThree
	weeklySixDaysMinimumSalaryAnThree
	transportRate
	transportPaidAfter
	ovtLimit1
	ovtLimit2
	ovtRate1
	ovtRate2
	sundayPublicHolidayFactor
	companyCostFactor
	minTimeBetweenWeek
	transportPaidAfterStr
	ovtLimit1Str
	ovtLimit2Str
	minTimeBetweenWeekStr
	totMinPerDayForContDay
	lunchMinimumForContDay
	totMinPerDayForContDayStr
	weeklyBaseEffNumHoursStr
	weeklyOvtOneLimitStr
	weeklyOvtTwoLimitStr
	weeklyOvtThreeLimitStr
	lunchMinimumStr
	lunchMinimumForContDayStr
	employeeStatus
	isAutomaticSentContract
	costCenterPrepsType
	costCenterShootingType
	costCenterWrapType
	documentPackageId
	useCarAllowance
	carAllowanceRate
	carAllowance
	useProductionFeeAllowance
	productionFeeAllowanceRate
	productionFeeAllowance
	useDinnerPerDiem
	dinnerPerDiem
	useComputerAllowance
	computerAllowanceRate
	computerAllowance
	minimumHourGuaranteed
	minimumHourGuaranteedType
	minimumHourGuaranteedStr
	beforeCallTimeRate
	ifWorkAboveDailyHours
	usePhoneAllowance
	phoneAllowanceRate
	phoneAllowance
	useBoxKitAllowance
	boxKitAllowanceRate
	boxKitAllowance
	useAbroadPerDiem
	abroadPerDiem
	useHotelPerDiem
	useLunchPerDiem
	note
	weeklyFiveDaysEffNumHoursStr
	weeklyFiveDaysEquNumHoursStr
	weeklySixDaysEffNumHoursStr
	weeklySixDaysEquNumHoursStr
	contractReference
	contractType
	dailyOvertime
	ovtLimit
	ovtLimitContinuousDay
	ovtLimitContinuousDay1
	ovtLimitContinuousDay2
	ovtLimitStr
	ovtLimitContinuousDayStr
	ovtLimitContinuousDay1Str
	ovtLimitContinuousDay2Str
	monthlyRate
	useTravelDailyRate
	baseSalary1PayCode
	baseSalary2PayCode
	baseSalary3PayCode
	baseSalary4PayCode
	dayOvt1PayCode
	dayOvt2PayCode
	dayOvt3PayCode
	weekOvt1PayCode
	weekOvt2PayCode
	weekOvt3PayCode
	nightTimePayCode
	restPayCode
	transportPayCode
	sundayPayCode
	seventhDayPayCode
	sixthDayPayCode
	beforeTimePayCode
	travelAllowancePayCode
	carAllowancePayCode
	phoneAllowancePayCode
	computerAllowancePayCode
	boxKitAllowancePayCode
	productionFeeAllowancePayCode
	lunchPerDiemPayCode
	dinerPerDiemPayCode
	hotelPerDiemPayCode
	abroadPerDiemPayCode
	collectiveAgreement
	echelon
	analyticCode
	cdiMonthlyReferenceSalary
	publicHolidayCalendar
	dpaeStatus
	dpaeReturnMessage
`;

const contractTemplateColumns = `
	defaultDayType
	dailyRate
	travelDailyRate
	hourlyRate
	overtimeRate
	nightTimeRate
	hourBeforeTimeRate
	kmRate
	lunchPerDiem
	hotelPerDiem
	dailyRightTransfer
	nightTimeStart
	nightTimeEnd
	minTimeBetweenDays
	lunchMinimum
	totMinPerDay
	totMinPerWeek
	sixthDay
	seventhDay
	timeManagementType
	weeklyOvertime
	workSixDays
	weeklyFiveDaysMinimumSalary
	weeklySixDaysMinimumSalary
	weeklyFiveDaysEffNumHours
	weeklyFiveDaysEquNumHours
	weeklySixDaysEffNumHours
	weeklySixDaysEquNumHours
	weeklyBonusAmount
	weeklyFiveDaysRefSalary
	weeklySixDaysRefSalary
	weeklyOvtOneLimit
	weeklyOvtOneRate
	weeklyOvtTwoLimit
	weeklyOvtTwoRate
	weeklyOvtThreeLimit
	weeklyOvtThreeRate
	weeklyBaseEffNumHours
	hourlyRateAnnexThree
	weeklyBonusAmountFiveDays
	weeklyBonusAmountSixDays
	weeklyBaseRefSalary
	weeklyBaseMinimumSalary
	weeklyFiveDaysMinimumSalaryAnThree
	weeklySixDaysMinimumSalaryAnThree
	transportRate
	transportPaidAfter
	ovtLimit1
	ovtLimit2
	ovtRate1
	ovtRate2
	sundayPublicHolidayFactor
	companyCostFactor
	minTimeBetweenWeek
	transportPaidAfterStr
	ovtLimit1Str
	ovtLimit2Str
	minTimeBetweenWeekStr
	totMinPerDayStr
	totMinPerWeekStr
	totMinPerDayForContDay
	lunchMinimumForContDay
	totMinPerDayForContDayStr
	weeklyBaseEffNumHoursStr
	weeklyOvtOneLimitStr
	weeklyOvtTwoLimitStr
	weeklyOvtThreeLimitStr
	lunchMinimumStr
	lunchMinimumForContDayStr
	employeeStatus
	isAutomaticSentContract
	costCenterPrepsType
	costCenterShootingType
	costCenterWrapType
	documentPackageId
	useCarAllowance
	carAllowanceRate
	carAllowance
	useProductionFeeAllowance
	productionFeeAllowanceRate
	productionFeeAllowance
	useDinnerPerDiem
	dinnerPerDiem
	useComputerAllowance
	computerAllowanceRate
	computerAllowance
	minimumHourGuaranteed
	minimumHourGuaranteedType
	minimumHourGuaranteedStr
	beforeCallTimeRate
	ifWorkAboveDailyHours
	usePhoneAllowance
	phoneAllowanceRate
	phoneAllowance
	useBoxKitAllowance
	boxKitAllowanceRate
	boxKitAllowance
	useAbroadPerDiem
	abroadPerDiem
	useHotelPerDiem
	useLunchPerDiem
	note
	weeklyFiveDaysEffNumHoursStr
	weeklyFiveDaysEquNumHoursStr
	weeklySixDaysEffNumHoursStr
	weeklySixDaysEquNumHoursStr
	contractReference
	contractType
	dailyOvertime
	ovtLimit
	ovtLimitContinuousDay
	ovtLimitContinuousDay1
	ovtLimitContinuousDay2
	ovtLimitStr
	ovtLimitContinuousDayStr
	ovtLimitContinuousDay1Str
	ovtLimitContinuousDay2Str
	monthlyRate
	useTravelDailyRate
	baseSalary1PayCode
	baseSalary2PayCode
	baseSalary3PayCode
	baseSalary4PayCode
	dayOvt1PayCode
	dayOvt2PayCode
	dayOvt3PayCode
	weekOvt1PayCode
	weekOvt2PayCode
	weekOvt3PayCode
	nightTimePayCode
	restPayCode
	transportPayCode
	sundayPayCode
	seventhDayPayCode
	sixthDayPayCode
	beforeTimePayCode
	travelAllowancePayCode
	carAllowancePayCode
	phoneAllowancePayCode
	computerAllowancePayCode
	boxKitAllowancePayCode
	productionFeeAllowancePayCode
	lunchPerDiemPayCode
	dinerPerDiemPayCode
	hotelPerDiemPayCode
	abroadPerDiemPayCode
	collectiveAgreement
	echelon
	analyticCode
	cdiMonthlyReferenceSalary
	publicHolidayCalendar
`;
